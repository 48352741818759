<template>
	<main class="document row max-width-grid padding-y-24">
		<div class="bp-1024:display-flex bp-1024:align-items-center">
			<div>
				Having trouble viewing this document?
				<a href="https://get.adobe.com/reader/"
					>Install the latest free Adobe Acrobat Reader</a
				>
				and use the download link.
			</div>
			<ul
				class="reset-list display-flex flex-wrap-wrap bp-1024:margin-l-auto"
				style="flex: 0 0 auto;"
			>
				<li
					class="margin-r-12 margin-t-12 bp-1024:margin-t-0 bp-1024:margin-l-24"
				>
					<a class="button --on-light" :href="downloadLink"
						>Download</a
					>
				</li>

				<li class="margin-t-12 bp-1024:margin-t-0" v-if="isPDF">
					<a
						class="button --on-light"
						:href="docPathBase + fileRoute"
						target="_blank"
					>
						View Full Screen
					</a>
				</li>
			</ul>
		</div>
		<div class="margin-t-32 width-100" v-html="iframe"></div>
	</main>
</template>

<script>
export default {
	props: {
		year: {
			type: String,
			default: '',
		},
		month: {
			type: String,
			default: '',
		},
		day: {
			type: String,
			default: '',
		},
		filepath: {
			type: String,
			default: '',
		},
	},

	name: 'Document',

	computed: {
		docPathBase() {
			return 'https:' + this.$root.bucket + '/documents/';
		},
		fileRoute() {
			return this.fileDate + '/' + this.filepath;
		},
		fileDate() {
			return this.year + '/' + this.month + '/' + this.day;
		},
		downloadLink() {
			return (
				`${this.$root.proxy}/services/download_file.ashx?file_location=` +
				this.docPathBase +
				this.fileRoute
			);
		},
		isPDF() {
			return this.filepath.split('.').pop() == 'pdf';
		},
		iframe() {
			let _iframe = '';

			let _agents = [
				'Mobile',
				'Windows CE',
				'Blazer',
				'BlackBerry',
				'Android',
				'webOS',
				'wOSBrowser',
				'iPhone',
				'iPad',
				'MSIE',
				'Trident/7',
			];
			let _ua = navigator.userAgent;

			let _useGoogle =
				_agents.some(v => _ua.includes(v)) ||
				(_ua.includes('Macintosh; Intel Mac OS') &&
					_ua.includes('Firefox')) ||
				_ua.includes('like Gecko) Version/');

			switch (this.filepath.split('.').pop()) {
				case 'htm':
				case 'html':
					_iframe =
						"<br /><iframe src='" +
						this.docPathBase +
						this.fileRoute +
						"' class='embedded' style='border:none;'></iframe>";
					break;
				case 'mp3':
					_iframe =
						"<br /><audio controls><source src='" +
						this.docPathBase +
						this.fileRoute +
						"' type='audio/mpeg'>Your browser does not support audio embedding.</audio><br /><br /><br /><br />";
					break;
				default:
					if (_useGoogle) {
						_iframe =
							"<iframe src='" +
							this.docPathBase +
							this.fileRoute +
							'&amp;embedded=true&amp;date=' +
							this.fileDate +
							"' class='embedded'></iframe>";
					} else {
						_iframe =
							'<object data="' +
							this.docPathBase +
							this.fileRoute +
							'#view=FitH&amp;toolbar=1&" type="application/pdf" class="embedded"><p>Your document is ready to be downloaded.</p></object>';
					}
			}

			return _iframe;
		},
	},

	created() {
		this.setMetaSidearm('Documents');
	},
};
</script>

<style lang="scss">
.embedded {
	width: 100%;
	height: calc(1100px);
	border: 1px solid var(--color-default-theme-stroke);
}
</style>
